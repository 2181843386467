//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions, mapGetters } from 'vuex';

import Chat from '@/components/arbitrator/Deals/Chat.vue';
import TheForm from '@/components/arbitrator/Deals/Form.vue';
import List from '@/components/arbitrator/Deals/List.vue';
import TradeInfo from '@/components/arbitrator/Deals/TradeInfo.vue';
import Pagination from '@/components/Pagination.vue';
// import Modal from './Modal.vue';
import constant from '~/const';

export default {
  components: {
    Chat,
    TheForm,
    List,
    TradeInfo,
    Pagination,
    // Modal,
  },

  data() {
    return {
      theme: this.$vuetify.theme.dark,
      constant,
      // warning: {
      //   show: true,
      //   icon: constant.notifications.WARNINGS_LIST.FIRST_MESSAGE.icon,
      //   title: constant.notifications.WARNINGS_LIST.FIRST_MESSAGE.title,
      //   description: constant.notifications.WARNINGS_LIST.FIRST_MESSAGE.description,
      // },
      editingDeal: {
        show: false,
        data: {},
      },
    };
  },

  computed: {
    ...mapState('common', ['generalLoader']),
    ...mapState('arbitratorDeals', ['page', 'pages', 'data']),
    ...mapState('arbitratorChat', ['messages']),
    ...mapGetters('arbitratorChat', ['hasMoreMessages']),
    ...mapGetters('currencies', ['getP2PCurrencyById']),

    tab() {
      return this.$route.params.tab;
    },

    getTradeStatus() {
      return this.currentTrade?.status;
    },

    tradeId() {
      return this.$route.query.tradeId ?? 0;
    },

    chatId() {
      return this.currentTrade?.conversationId ?? 0;
    },

    currentTrade() {
      return this.data?.find((e) => e.id === Number(this.tradeId)) ?? {};
    },

    usernames() {
      return [
        this.currentTrade.cryptoSeller?.username ?? '',
        this.currentTrade.cryptoBuyer?.username ?? '',
      ];
    },

    users() {
      return [
        this.currentTrade.cryptoSeller ?? {},
        this.currentTrade.cryptoBuyer ?? {},
      ];
    },

    confirmTradeText() {
      const seller = this.currentTrade.cryptoSeller?.username;
      const sellerQty = this.currentTrade.cryptoSellerAmount;
      const sellerCurr = this.getP2PCurrencyById(this.currentTrade.cryptoSellerCurrencyId)?.title;
      const buyer = this.currentTrade.cryptoBuyer?.username;
      const buyerQty = this.currentTrade.cryptoBuyerAmount;
      const buyerCurr = this.getP2PCurrencyById(this.currentTrade.cryptoBuyerCurrencyId)?.title;
      return this.$t('Confirm trade data', {
        buyer, buyerQty, buyerCurr, sellerQty, sellerCurr, seller,
      });
    },

    cancelTradeText() {
      const seller = this.currentTrade.cryptoSeller?.username;
      const sellerQty = this.currentTrade.cryptoSellerAmount;
      const sellerCurr = this.getP2PCurrencyById(this.currentTrade.cryptoSellerCurrencyId)?.title;
      const buyer = this.currentTrade.cryptoBuyer?.username;
      const buyerQty = this.currentTrade.cryptoBuyerAmount;
      const buyerCurr = this.getP2PCurrencyById(this.currentTrade.cryptoBuyerCurrencyId)?.title;
      return this.$t('Cancel trade data', {
        buyer, buyerQty, buyerCurr, sellerQty, sellerCurr, seller,
      });
    },
  },

  async mounted() {
    this.setGeneralLoader(true);
    await Promise.allSettled([
      this.loadMyDeals(),
      this.initiateSocketAction(),
    ]);
    this.setGeneralLoader(false);
  },

  methods: {
    ...mapActions('common', [
      'setGeneralProgress',
      'setGeneralLoader',
      'setSuccessNotification',
      'setErrorNotification',
      'confirmAction',
    ]),
    ...mapActions('arbitratorDeals', {
      loadDeals: 'loadData',
      confirmTradeAction: 'confirmTrade',
      cancelTradeAction: 'cancelTrade',
      setPageAction: 'setPage',
    }),
    ...mapActions('arbitratorChat', {
      loadMessages: 'loadData',
      sendMessageAction: 'sendMessage',
      initiateSocketAction: 'initiateSocket',
    }),
    ...mapActions('tradersDeals', {
      editTradeAction: 'editDeal',
    }),

    setWarning(show) {
      this.warning.show = show;
    },

    async getMessages() {
      await this.loadMessages(this.chatId);
    },

    setPage(page) {
      this.setPageAction(page);
      this.loadMyDeals();
    },

    async loadMyDeals() {
      await this.loadDeals();
      if (this.data) this.select(this.data[0]?.id);
    },

    async checkUsers(data) {
      // Проверяем, является ли receiverId числом или массивом
      if (Array.isArray(data.receivers.ids)) {
        data.receivers.ids.forEach(async (receiver) => {
          // Если receiverId является массивом, отправляем сообщение каждому пользователю в массиве
          await this.sendMessage(data, receiver.id, receiver.username);
        });
      } else {
        // Если receiverId является числом, отправляем сообщение только одному пользователю
        await this.sendMessage(data, data.receivers.id, data.receivers.username);
      }
    },

    async sendMessage(data, receiverId, receiverUsername) {
      const jsonData = {
        receiverId,
        receiverUsername,
        message: data.text,
        id: this.chatId,
      };

      if (data.attachment) {
        // Конвертируем прикрепленный файл в строку base64
        const base64String = await this.convertToBase64(data.attachment);
        const fileData = {
          name: data.attachment.name,
          data: base64String,
        };
        jsonData.files = [fileData]; // Добавляем информацию о файле в объект JSON
      }

      // Отправляем сообщение на сервер
      // this.setGeneralLoader(true);
      await this.sendMessageAction(jsonData);
      // this.setGeneralLoader(false);
    },

    async convertToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          // Извлекаем только содержимое файла из base64 строки
          const base64String = reader.result;
          resolve(base64String);
        };
        reader.onerror = (error) => {
          reject(error);
        };
      });
    },

    editTrade() {
      this.editingDeal.show = true;
    },

    // applyEdit({ input }) {
    //   this.confirmAction({
    //     title: 'Are you sure you want to edit trade?',
    //     callback: async () => {
    //       this.setGeneralProgress(true);
    //       try {
    //         await this.editTradeAction({ ...input, tradeId: this.tradeId });
    //         this.editingDeal.show = false;
    //         this.setSuccessNotification('Deal edited');
    //       } catch (error) {
    //         this.setErrorNotification(error.message);
    //       }
    //       this.setGeneralProgress(false);
    //     },
    //   });
    // },

    async select(id) {
      if (id !== Number(this.$route.query.tradeId)) this.$router.replace({ query: { tradeId: id } });
      this.setGeneralLoader(true);
      await this.getMessages();
      this.setGeneralLoader(false);
    },

    async confirmTrade() {
      this.confirmAction({
        title: 'Are you sure you want to confirm this trade?',
        text: this.confirmTradeText,
        callback: async () => {
          this.setGeneralLoader(true);
          try {
            await this.confirmTradeAction(this.tradeId);
            this.setSuccessNotification('You have successfuly confirmed the trade');
            this.loadMyDeals();
          } catch (error) {
            this.setErrorNotification(error.message);
          }
          this.setGeneralLoader(false);
        },
      });
    },

    cancelTrade() {
      this.confirmAction({
        title: 'Are you sure you want to cancel this trade?',
        text: this.cancelTradeText,
        callback: async () => {
          this.setGeneralLoader(true);
          try {
            await this.cancelTradeAction(this.tradeId);
            this.setSuccessNotification('You have successfuly cancelled the trade');
            this.loadMyDeals();
          } catch (error) {
            this.setErrorNotification(error.message);
          }
          this.setGeneralLoader(false);
        },
      });
    },
  },
};
