//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';

export default {

  props: {
    loader: { type: Boolean, default: false },
    users: { type: Array, default: () => ([]) },
    send: { type: Function, required: true },
    trade: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      message: '',
      receiverId: [],
      attachState: null,
      rules: {
        fileLength: (value) => (value?.size < 1000000) || '',
      },
    };
  },

  computed: {
    attach() {
      return this.attachState;
    },

    isFileLarge() {
      if (!this.attach) return false;
      return this.attach.size > 1000000;
    },

    disabledSend() {
      return !!this.receiverId && this.message.length > 0 && !this.isFileLarge && this.message.length <= 512;
    },

    getReceivers() {
      return [...this.$props.users, { username: this.$t('Send to all'), ids: [{ ...this.$props.users[0] }, { ...this.$props.users[1] }] }];
    },
  },

  methods: {
    ...mapActions('common', ['setSavedFilters', 'setSuccessNotification']),
    keypressHandler() {
      const msg = this.message.trim();
      if (!msg && !this.attach) return;

      const data = {
        text: msg,
        receivers: { ...this.receiverId },
        attachment: this.attach,
      };

      this.$props.send(data);

      this.receiverId = '';
      this.message = '';
      this.attachState = null;
    },

    getUserRole(user) {
      if (user.username === this.$props.trade.cryptoSeller.username) {
        return this.$t('Seller');
      }
      return user.username !== this.$t('Send to all') ? this.$t('Buyer') : this.$t('Send to all');
    },

    attachFile() {
      this.$refs.attachRef.click();
    },

    clearAttach() {
      this.attachState = null;
      this.setSuccessNotification('File unattached');
    },
  },

  watch: {
    attach(value) {
      if (value) {
        this.setSuccessNotification('File attached');
      }
    },
  },
};
